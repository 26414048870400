<template>
  <v-container class="fill-height">
    <v-row class="fill-height" justify="center" align="center">
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <AuthCard
          :title="$t('auth.changePasswordView.title')"
          @submit="onSubmit"
        >
          <v-card-text class="pb-0">
            <VxTextField
              v-model="form.currentPassword"
              :label="$t('auth.changePasswordView.currentPasswordField.label')"
              name="currentPassword"
              type="password"
              autocomplete="off"
              :placeholder="
                $t('auth.changePasswordView.currentPasswordField.placeholder')
              "
              rules="required"
            />
            <VxTextField
              v-model="form.newPassword"
              :label="$t('auth.changePasswordView.newPasswordField.label')"
              name="newPassword"
              type="password"
              autocomplete="off"
              :placeholder="
                $t('auth.changePasswordView.newPasswordField.placeholder')
              "
              rules="required"
            />
            <VxTextField
              v-model="form.confirmNewPassword"
              :label="$t('auth.changePasswordView.confirmPasswordField.label')"
              name="confirmNewPassword"
              type="password"
              autocomplete="off"
              :placeholder="
                $t('auth.changePasswordView.confirmPasswordField.placeholder')
              "
              rules="required|confirmed:newPassword"
            />
          </v-card-text>
        </AuthCard> </v-col></v-row
  ></v-container>
</template>

<script>
import axios from "axios";
const $http = axios.create({
  baseURL: ``,
});

import AuthCard from "./Auth/components/AuthCard.vue";
import { VxTextField } from "@/core-ui";
import { hasSnackbarAccess } from "@/mixins/ui";

export default {
  name: "ChangePasswordView",
  components: { AuthCard, VxTextField },
  mixins: [hasSnackbarAccess],
  data: () => ({
    form: {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
    },
  }),
  methods: {
    async onSubmit({ values, setErrors }) {
      try {
        console.log(values);
        await $http.put("api/users/change_password", {
          user: {
            old_password: values.currentPassword,
            password: values.newPassword,
            password_confirmation: values.confirmNewPassword,
          },
        });

        // re login with the new credentials to refresh the auth token
        await this.$store.dispatch("auth/authenticate", {
          credentials: {
            email: this.$store.state.auth.email,
            password: values.newPassword,
          },
        });

        this.$router.back();
        this.showSnackbar({
          text: this.$t("auth.changePasswordView.successActionText"),
        });
      } catch {
        setErrors([this.$t("auth.changePasswordView.failedActionText")]);
      }
    },
    async login() {},
  },
};
</script>
